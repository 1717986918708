import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import PageLayout from '../components/page-layout';
import { SectionLayout, ShortHeroSection, Training } from '../components/sections';

import {
    HERO_SECTION_ID,
    TRAINING,
} from '../constants';

export default function AboutPage() {

    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          keywords
        }
      }
    }
  `);

    return (
        <>
            <Helmet
            htmlAttributes={{
            lang: 'en',
  }}
            >
                <meta charSet="utf-8" />
                <title>{data.site.siteMetadata.title}</title>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <meta name="description" content={data.site.siteMetadata.description} />
                <meta name="author" content={data.site.siteMetadata.author} />
                <meta name="keywords" content={data.site.siteMetadata.keywords} />
                <link rel="canonical" href={data.site.siteMetadata.siteUrl} />
            </Helmet>
            <PageLayout>
                <ShortHeroSection sectionId={HERO_SECTION_ID} sectionTitle="Home / Training" />
                <SectionLayout
                    sectionId={TRAINING}
                    sectionTitle='Ministry Approved Security Guard Training'
                >
                    <Training />
                </SectionLayout>
            </PageLayout>
        </>
    );
}
